import Vue from "vue";
import Vuex from 'vuex'
Vue.use(Vuex)
const store = new Vuex.Store({
    state(){
        return {
            obj:{
                id:'',
                role:'',
                name:'',
                value:false,
                teamId: '',
                isCaptain: 0
            }
        }
    },
    mutations:{
        updateObj(state,obj){
            state.obj = obj
        },
        clearObj(state){
            state.obj={
                id:'',
                role:'',
                name:'',
                value:false,
                teamId: '',
                isCaptain: 0
            }
        }
    }
})
export default store
